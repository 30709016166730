<template>
	<div id="app">
		<router-view />
		<van-tabbar active-color="#0084cf" v-model="activeIndex" v-show="showTabbar" fixed placeholder>
			<template v-for="item in menuList">
				<van-tabbar-item :key="item.index" :name="item.index" :icon="item.icon"
					:to="item.path">{{item.displayName}}</van-tabbar-item>
			</template>
		</van-tabbar>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				showTabbar: true,
				activeIndex: 1,
				menuList: [{
					index: 0,
					displayName: '商城',
					icon: 'shop',
					routerName: 'mall',
					path: '/mall'
				}, {
					index: 1,
					displayName: '活动',
					icon: 'fire',
					routerName: 'activity',
					path: '/activity'
				}, {
					index: 2,
					displayName: '我的',
					icon: 'contact',
					routerName: 'user',
					path: '/user'
				}]
			}
		},
		watch: {
			$route(to, from) {
				let that = this
				const toName = to.name
				let _curMenu = that.menuList.filter(x => x.routerName === toName)
				if (_curMenu.length > 0) {
					that.activeIndex = _curMenu[0].index
					that.showTabbar = true
				} else {
					that.showTabbar = false
				}
			}
		}
	}
</script>
<style>
	* {
		font-size: .5rem;
		line-height: .625rem;
	}

	.van-list__finished-text {
		font-size: .5rem !important;
	}

	*,
	:after,
	:before {
		box-sizing: border-box
	}

	body {
		background: #f7f8fa;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		height: 100%;
	}

	.van-tabbar--fixed {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px;
	}

	.van-tabbar {
		padding: 10px 0 !important;
		/* height: auto !important; */
	}

	.van-tabbar-item__icon {
		/* font-size: 1rem !important; */
	}

	.van-notify {
		font-size: .5rem !important;
	}

	.van-nav-bar__arrow {
		font-size: .6rem !important;
		color: #000 !important;
	}

	.van-nav-bar__title {
		font-size: 20px !important;
	}

	.van-dialog__message,
	.van-dialog__header,
	.van-dialog__footer .van-button__text {
		font-size: .6rem !important;
		line-height: .8rem !important;
	}

	.van-notify {
		padding: 13px 12px !important;
	}

	.van-empty__description {
		font-size: .5rem !important;
	}
</style>