import {
	login,
	getLoginUserInfo
} from '@/api/user'

import {
	getAppConfig
} from '@/api/public'

import {
	getToken,
	setToken,
	removeToken
} from '@/utils/auth'

const identity = {
	state: {
		token: getToken(),
		userInfo: {},
		roles: [],
		permissions: [],
		config: {}
	},
	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_USERINFO: (state, userInfo) => {
			state.userInfo = userInfo
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_PERMISSIONS: (state, permissions) => {
			state.permissions = permissions
		},
		SET_APPCONFIG: (state, config) => {
			state.config = config
		},
	},
	actions: {
		//登录
		Login({
			commit,
			state
		}, para) {
			return new Promise((resolve, reject) => {
				login(para).then(res => {
					if (res.code === 200) {
						setToken(res.token)
						commit('SET_TOKEN', res.token)
						commit('SET_USERINFO', res.result)
						commit('SET_APPCONFIG', res.result)
						resolve()
					} else {
						resolve(res);
					}

				}).catch(error => {
					reject(error)
				})
			})
		},
		//退出系统
		LogOut({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				commit('SET_TOKEN', '')
				commit('SET_USERINFO', '')
				commit('SET_APPCONFIG', '')
				removeToken()
				resolve()
			})
		},
		//获取用户信息
		GetInfo({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				getLoginUserInfo().then(res => {
					if (res && res.code === 200) {
						const response = res.result;
						if (response.roles && response.roles.length > 0) { // 验证返回的roles是否是一个非空数组
							commit('SET_ROLES', response.roles)
							commit('SET_PERMISSIONS', response.permissions)
						} else {
							commit('SET_ROLES', ['ROLE_DEFAULT'])
						}
						commit('SET_USERINFO', response.user);
						resolve(res)
					} else {
						resolve(res);
					}

				}).catch(error => {
					reject(error)
				})
			})
		},
		GetConfig({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				getAppConfig().then(res => {
					if (res && res.code === 200) {
						const response = res.result;
						commit('SET_APPCONFIG', response);
						resolve(res)
					} else {
						resolve(res);
					}

				}).catch(error => {
					reject(error)
				})
			})
		}
	}
}
export default identity