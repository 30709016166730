import Vue from 'vue'
import Vuex from 'vuex'
import identity from './modules/identity'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		identity
	}
})

export default store