import router from './router'
import store from './store'
import {
	getToken
} from '@/utils/auth'

const whiteList = ['/user/login', '/user/register', '/user/findpwd']

router.beforeEach((to, from, next) => {
	// NProgress.start()
	if (getToken()) {
		/* has token*/
		if (to.path === '/user/login' || to.path === '/user/register') {
			next({
				path: '/'
			})
			//NProgress.done()
		} else {
			if (store.state.identity.roles.length === 0) {
				store.dispatch('GetInfo').then(res => {
					store.dispatch('GetConfig').then(res2 => {
						next()
					})
					// 拉取user_info
					//next({...to,replace: true}) // hack方法 确保addRoutes已完成

				})
			} else {
				next()
			}
		}
	} else {
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免登录白名单，直接进入
			next()
		} else {
			// 否则全部重定向到登录页
			next({
				path: '/user/login',
				query: {
					redirect: to.fullPath
				}
			})
			//NProgress.done()
		}
	}
})

router.afterEach(() => {
	//NProgress.done()
})