import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	path: "/",
	redirect: "/activity",
}, {
	path: "/user/login",
	name: "login",
	component: () => import("@/views/user/login")
}, {
	path: "/user/register",
	name: "register",
	component: () => import("@/views/user/register")
}, {
	path: "/user/findpwd",
	name: "findpwd",
	component: () => import("@/views/user/findpwd")
}, {
	path: "/user",
	name: "user",
	component: () => import("@/views/user/index")

}, {
	path: "/user/address",
	name: "userAddress",
	component: () => import("@/views/user/address/list")

}, {
	path: "/user/address/edit",
	name: "userAddressEdit",
	component: () => import("@/views/user/address/edit")
}, {
	path: "/user/Integral",
	name: "userIntegral",
	component: () => import("@/views/user/Integral/statement")
}, {
	path: "/notify/list",
	name: "notifyList",
	component: () => import("@/views/notify/list")
}, {
	path: "/activity",
	name: "activity",
	component: () => import("@/views/activity/index")
}, {
	path: "/activity/detail/:id",
	name: "activityDetail",
	props: true,
	component: () => import("@/views/activity/detail")
}, {
	path: "/activity/signup",
	name: "activitySignup",
	component: () => import("@/views/activity/signUp")
}, {
	path: "/activity/submit/:id",
	name: "activitySubmit",
	props: true,
	component: () => import("@/views/activity/submit")
}, {
	path: "/mall",
	name: "mall",
	component: () => import("@/views/mall/index")
}, {
	path: "/mall/goods/detail/:id",
	name: "goodsDetail",
	props: true,
	component: () => import("@/views/mall/goods/detail")
}, {
	path: "/mall/cart",
	name: "mallCart",
	component: () => import("@/views/mall/cart/index")
}, {
	path: "/order",
	name: "order",
	component: () => import("@/views/order/index")
}, {
	path: "/order/detail/:id",
	name: "orderDetail",
	props: true,
	component: () => import("@/views/order/detail")
}, {
	path: "/order/submit",
	name: "orderSubmit",
	component: () => import("@/views/order/submit")
}]

const router = new VueRouter({
	routes
})

export default router