import axios from 'axios'
import {
	Notify,
	Toast
} from 'vant';
// import store from '@/store'
import {
	getToken,
	removeToken
} from '@/utils/auth'


let loading //定义loading变量

function startLoading() {
	loading = Toast.loading({
		message: '加载中...',
		forbidClick: true,
		duration: 0, // 持续展示 toast
		overlay: true
	});
}

function endLoading() {
	loading.clear();
}
//那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
//调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
	if (needLoadingRequestCount === 0) {
		startLoading()
	}
	needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
	if (needLoadingRequestCount <= 0) return
	needLoadingRequestCount--
	if (needLoadingRequestCount === 0) {
		endLoading()
	}
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['platform'] = 'App'
// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: process.env.VUE_APP_BASE_API,
	// 超时
	timeout: 500000
})
// request拦截器
service.interceptors.request.use(
	config => {
		if (getToken()) {
			config.headers['X-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
		}
		showFullScreenLoading();
		return config
	},
	error => {
		Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(response => {
		const res = response.data
		const code = res.code
		tryHideFullScreenLoading();
		if (code === undefined) {
			return res;
		} else if (code === 401) {
			//token过期
			Notify({
				type: 'danger',
				message: '当前登录状态已过期，请重新登录',
				onClose: function() {
					removeToken();
					location.reload();
				}
			});
		} else if (code === 402) {
			//openId未注册,交给前台处理
			return res
		} else if (code != 200) {
			Notify({
				type: 'danger',
				message: res.message
			});
			return Promise.reject('error')
		} else {
			return res
		}
	},
	error => {
		tryHideFullScreenLoading();
		Notify({
			type: 'danger',
			message: error.message
		})
		return Promise.reject(error)
	}
)

export default service