import request from '@/utils/request'
const conUIr = '/app' //公用前缀

export function login(params) {
	return request({
		url: conUIr + '/login',
		method: 'POST',
		data: params
	})
}

export function getIdentity(params) {
	return request({
		url: conUIr + '/Personal/Identity',
		method: 'POST',
		data: params
	})
}

export function getLoginUserInfo() {
	return request({
		url: conUIr + '/Personal/Info',
		method: 'GET'
	})
}

export function uploadAvatar(data) {
	return request({
		url: conUIr + '/Personal/Avatar/Upload',
		data,
		method: 'POST'
	})
}

export function userRegister(data) {
	return request({
		url: conUIr + '/Personal/Register',
		data,
		method: 'POST'
	})
}

export function findPassword(data) {
	return request({
		url: conUIr + '/FindPassword',
		data,
		method: 'POST'
	})
}

export function checkMallAccount() {
	return request({
		url: conUIr + '/mall/account/check',
		method: 'GET'
	})
}

export function getIntegralLogList(params) {
	return request({
		url: conUIr + "/integra/log/load",
		method: "GET",
		params,
	});
}

export function getMyActivityList() {
	return request({
		url: conUIr + '/personal/activity/load',
		method: 'GET'
	})
}

export function getStatistics() {
	return request({
		url: conUIr + '/Personal/Statistics',
		method: 'GET'
	})
}

//文件上传
export function uploadFile(file) {
	return request({
		url: conUIr + '/file/upload',
		method: 'POST',
		data: file,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}