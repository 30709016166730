import request from '@/utils/request'
const conUIr = '/app' //公用前缀

export function getAppConfig() {
	return request({
		url: conUIr + '/AppConfig/get',
		method: 'GET'
	})
}

export function getAppOrg(params) {
	return request({
		url: conUIr + '/appOrg/load',
		method: 'GET',
		params
	})
}

export function getCategory(TypeId) {
	let params = {
		typeId: TypeId
	}
	return request({
		url: conUIr + "/category/load",
		method: "GET",
		params: params
	});
}

export function loadMessage(params) {
	return request({
		url: conUIr + '/message/list',
		params,
		method: 'GET'
	})
}

export function loadNewMessageCount() {
	return request({
		url: conUIr + '/message/newcount',
		method: 'GET'
	})
}

export function setMessageRead(data) {
	return request({
		url: conUIr + '/message/read',
		data,
		method: 'POST'
	})
}

//文件上传
export function uploadFile(file) {
	return request({
		url: conUIr + '/UploadFile',
		method: 'POST',
		data: file,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}