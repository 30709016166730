import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'lib-flexible'
import Vant from "vant"
import moment from 'moment'
import {
	Lazyload
} from 'vant';

import './permission' // permission control
import "./utils/common";

import 'normalize.css/normalize.css'
import 'vant/lib/index.css'


Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Lazyload)

//定义一个全局过滤器实现日期格式化
Vue.filter('datefmt', function(input, fmtstring) { //当input为时间戳时，需转为Number类型
	// 使用momentjs这个日期格式化类库实现日期的格式化功能
	return moment(input).format(fmtstring);
})
Vue.prototype.$moment = moment

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')